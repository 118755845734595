<template>
    <v-card>
        
        <v-card-text>
            <v-row class="pa-5 align-center">
                <v-col cols="11">
                    <v-row>
                        <h2 class="font-weight-regular text-center">Nuevo Personal</h2>
                    </v-row>
                </v-col>
            </v-row>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="nombres"
                            :rules="[fieldRules.require]"
                            label="Nombres"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="appaterno"
                            :rules="[fieldRules.require]"
                            label="Ap. Paterno"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="apmaterno"
                            :rules="[fieldRules.require]"
                            label="Ap. Materno"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="direccion"
                            :rules="[fieldRules.require]"
                            label="Direccion"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="telefono"
                            :rules="[fieldRules.require]"
                            label="Telefono"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>
                    
                </v-row>
            
                <v-btn
                  class="me-4"
                  color="info"
                  :loading="loading"
                  :disabled="loading"
                  depressed
                  @mousedown="validate"
                  @click="guardar"
                >
                    Guardar 
                </v-btn>
                <v-btn
                  class="me-4 text-white"
                  color="danger"
                  to="/personal"
                >
                    Regresar 
                </v-btn>
    
            </v-form>
        </v-card-text>
    </v-card>
</template>
<script>
import Swal from 'sweetalert2'
export default {
    data () {
        return {
            token : this.$store.getters.token,
            codigo : this.$route.params.id,
            loading: false,
            nombres : "",
            appaterno : "",
            apmaterno: "",
            direccion : "",
            telefono : "",
            valid: true,
            fieldRules: {
                require : (v) => !!v || "Campo requerido",
            },
            
        }
    },
    methods : {
        validate() {
            this.$refs.form.validate();
        },

        crearEntidad(){
            return {
                "nombres": this.nombres,
                "appaterno" : this.appaterno,
                "apmaterno" : this.apmaterno,
                "direccion": this.direccion,
                "telefono": this.telefono
            }
        },

        guardar(){
            if (this.valid == false) return;
            this.loading = true;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method : 'PUT',
                url : '/api/personal/'+this.codigo,
                data: this.crearEntidad()
            }).then(response => {
                this.loading = false;
                let icono = response.data.cod == 201 ? 'success' : 'error';
 
                Swal.fire({
                    title : "Sistema",
                    text: response.data.msje,
                    icon: icono
                });

                if(response.data.cod == 201){
                    this.$router.push('/personal');
                }

            }).catch(e => {
                Swal.fire({
                    title : "Sistema",
                    text: e.message,
                    icon: "error"
                })
                this.$router.push('/login');
            })
        },
        getData(){
            this.cargando = true;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method : "GET",
                url : "/api/personal/"+this.codigo
            }).then(response => {
                this.nombres = response.data.nombres;
                this.appaterno = response.data.appaterno;
                this.apmaterno = response.data.apmaterno;
                this.direccion = response.data.direccion;
                this.telefono = response.data.telefono;
                this.cargando = false;
            }).catch(e => {
                Swal.fire({
                    title : "Sistema",
                    text: e.message,
                    icon: "error"
                });
                this.$router.push('/login');
            });
        }
    },
    created(){
        this.getData();
    }
}
</script>
<style lang="">
    
</style>