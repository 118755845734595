<template>
  <v-card>
    <v-card-text>
        <v-row class="pa-5 align-center">
            <v-col cols="11">
                <v-row>
                    <h2 class="font-weight-regular text-center">Registrar Datos Por Cargo</h2>
                </v-row>
            </v-col>
        </v-row>
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
                <v-col cols="12">
                    <v-combobox
                        v-model="red"
                        :items="redes"
                        :rules="[fieldRules.required]"
                        density="comfortable"
                        variant="outlined"
                        label="RED"
                        :disabled="fijar"
                        @update:model-value="cambiaRed"
                    ></v-combobox>                    
                </v-col>
                <v-col cols="12">
                    <v-combobox
                        v-model="sede"
                        :items="sedes"
                        :rules="[fieldRules.required]"
                        density="comfortable"
                        variant="outlined"
                        label="Sede"
                        :disabled="fijar"
                        @update:model-value="cambiaSede"
                    ></v-combobox>
                </v-col>
                <v-col cols="10">
                    <v-combobox
                        v-model="cargo"
                        :items="cargos"
                        :rules="[fieldRules.required]"
                        density="comfortable"
                        variant="outlined"
                        label="Cargo"
                        :disabled="sede == null ? true : false || fijar"
                        @update:model-value="cambiaCargo"
                    ></v-combobox>
                </v-col>
                <v-col cols="2">
                    <v-switch
                    color="primary"
                    v-model="fijar"
                    label="fijar"
                    ></v-switch>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12">
                    <v-card prepend-icon="mdi-pencil-box">
                        <template v-slot:title>
                            Ingreso de Datos
                        </template>
                        <v-card-text>
                            <v-card v-if="cargo!=null">
                                <v-tabs
                                    v-model="tab"
                                    bg-color="deep-purple-darken-4"
                                    center-active
                                >
                                    <v-tab
                                        v-for="item in establecimientos"
                                        :key="item.idestablecimiento"
                                        :value="item.idestablecimiento"
                                    >
                                        {{ item.nombre }}
                                    </v-tab>
                                </v-tabs>
                                <v-window v-model="tab">
                                    <v-window-item
                                        v-for="estab in establecimientos"
                                        :key="estab.idestablecimiento"
                                        :value="estab.idestablecimiento"
                                    >
                                        <v-card
                                            color="basil"
                                            flat
                                        >
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="3" v-for="item of inputs.filter(x=> x.tipo ==1)" :key="item" >
                                                    <v-text-field
                                                        :label= "item.label"
                                                        v-model = "this.modelo[`est${estab.idestablecimiento}`][`${item.model}`]"
                                                        :rules="[fieldRules.number]"
                                                        @keyup="sumarCampos(estab)"
                                                        @change="sumarCampos(estab)"
                                                        variant="outlined"
                                                        type="number"
                                                        min = "0"
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-card prepend-icon="mdi-chart-bar">
                                                <template v-slot:title>
                                                    Datos de Brecha
                                                </template>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col :cols="foot.model == 'rrhh' ? 4 : foot.model == 'brecha' ? 2 : 6" v-for="foot of inputs.filter(x=> x.tipo ==0)" :key="foot">
                                                            <v-text-field
                                                                :label="foot.label"
                                                                :readonly="foot.model == 'personal' ? false : true"
                                                                :rules=" foot.model != 'brecha' ? [fieldRules.number] : []"
                                                                v-model="this.modelo[`est${estab.idestablecimiento}`][`${foot.model}`]"
                                                                variant="outlined"
                                                                type="number"
                                                                @keyup="foot.model == 'personal' ? calcularBrecha(estab) : false"
                                                                @change="foot.model == 'personal' ? calcularBrecha(estab) : false"
                                                                min = "0"
                                                            >
                                                            </v-text-field>
                                                        </v-col>
                                                        
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-card-text>
                                        </v-card>
                                    </v-window-item>
                                </v-window>
                            </v-card>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12">
                    <v-btn
                        class="me-4"
                        color="info"
                        :loading="loading"
                        :disabled="loading"
                        depressed
                        @mousedown="validate"
                        @click = "guardar"
                    >
                        Guardar 
                    </v-btn>
                    <v-btn
                        class="me-4 text-white"
                        color="danger"
                        to="/home"
                    >
                        Regresar 
                    </v-btn>
                </v-col>
            </v-row>
            
        </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2'

export default {
    data(){
        return {
            token : this.$store.getters.token,
            foo: 0,
            tab: null,
            cargo : null,
            red : null,
            sede : null,
            sedes : [],
            redes : [],
            loading: false,
            fijado : false,
            fijar : false,
            inputs : [],
            inputsfoot : [],
            cargos: [], 
            establecimientos : [],
            valid: true,            
            modelo : null,
            fieldRules: {
                required: (v) => !!v || "Campo requerido",
                number: (value) => {
                    const pattern = /^[0-9]/;
                    return pattern.test(value) || 'Solo numeros'
                }
                
            },
        }
    },
    methods : {
        validate() {
            this.$refs.form.validate();
        },
        increment () {
            this.foo = parseInt(this.foo,10) + 1
        },
        decrement () {
            this.foo = parseInt(this.foo,10) - 1
        },

        guardar(){
            if (this.valid == false) return;
            this.loading = true;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method : "POST",
                url : "/api/consulta",
                data: this.crearEntidad()
            }).then(response => {
                this.loading = false;
                let icono = response.data.cod == 201 ? 'success' : 'error';
 
                Swal.fire({
                    title : "Sistema",
                    text: response.data.msje,
                    icon: icono
                });

                if(response.data.cod == 201){
                    this.$router.push('/home');
                }
            }).catch(e =>{
                Swal.fire({
                    title : "Sistema",
                    text : e.message,
                    icon: "error"
                });
                this.$router.push('/login');
            })
        },

        limpiarData(){
            this.sedes = [];
            this.sede = null;
            this.cargo = null;
            this.inputs = [];
            this.modelo = null;
        },    
        crearEntidad(){
            return {
                "red": this.red.value,
                "sede": this.sede.value,
                "cargo": this.cargo.value,
                "detalles": this.modelo
            }
        }, 
        getCargos(){
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method : "GET",
                url: "/api/cargo/active"
            }).then(response => {
                response.data.forEach(x =>{
                   this.cargos.push({
                        "title": x.nombre,
                        "value": x.idcargo
                    })
                });
            }).catch(e =>{
                Swal.fire({
                    title : "Sistema",
                    text : e.message,
                    icon: "error"
                });
                this.$router.push('/login');
            })
        },
        getRedes(){
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method : "GET",
                url: "/api/red/active"
            }).then(response => {
                response.data.forEach(x =>{
                   this.redes.push({
                        "title": x.nombre,
                        "value": x.idred
                    })
                });
            }).catch(e =>{
                Swal.fire({
                    title : "Sistema",
                    text : e.message,
                    icon: "error"
                });
                this.$router.push('/login');
            })
        },
        getSedes(item){
            this.limpiarData();
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method : "GET",
                url: "/api/sede/listForRed/"+item
            }).then(response => {
                response.data.forEach(x =>{
                   this.sedes.push({
                        "title": x.nombre,
                        "value": x.idsede
                    })
                });
            }).catch(e =>{
                Swal.fire({
                    title : "Sistema",
                    text : e.message,
                    icon: "error"
                });
                this.$router.push('/login');
            })
        },
        getEstablecimientos(item){
            this.cargo = null;
            this.modelo = null;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method : "GET",
                url: "/api/establecimiento/listForSede/"+item
            }).then(response => {
                this.establecimientos = response.data;
                this.tab = response.data[0]
            }).catch(e =>{
                Swal.fire({
                    title : "Sistema",
                    text : e.message,
                    icon: "error"
                });
                this.$router.push('/login');
            })
        },
        getParametros(cargo){
            let label;
            let input;
            let labels = [];
            let models = [];
            let tipos = [];
            this.modelo = null;
            this.inputs = [];

            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method: "GET",
                url : "/api/parametro/campos/"+cargo
            }).then(response => {
                response.data.forEach(element => {
                    label = element.inputs;
                    input = element.valor;
                    let auxlabels = String(label).split(",");
                    let auxmodels = String(input).split(",");

                    for (let index = 0; index < auxlabels.length; index++) {
                        const rowlabel = auxlabels[index];
                        const rowmodel = auxmodels[index];
                        labels.push(rowlabel);
                        models.push(rowmodel);       
                        tipos.push(element.tipo);                 
                    }

                });
                
                this.modelo = Object.create({});

                for (let index = 0; index < labels.length; index++) {
                    let obj = {
                        label : labels[index],
                        model: models[index],
                        tipo: tipos[index]
                    };
                    this.inputs.push(obj);                    
                }

                this.establecimientos.forEach(x => {
                    this.modelo[`est${x.idestablecimiento}`] = Object.create({});
                    
                    for (let index = 0; index < labels.length; index++) {
                        let obj = {
                            model: models[index]
                        };
                        this.modelo[`est${x.idestablecimiento}`][`${obj.model}`] = 0;                                            
                    }
                });

            }).catch(e => {
                Swal.fire({
                    title : "Sistema",
                    text : e.message,
                    icon: "error"
                });
                this.$router.push('/login');
            })
        },
        cambiaCargo(item){
            this.getParametros(item.value);
        },
        cambiaRed(item){
            this.getSedes(item.value);
        },
        cambiaSede(item){
            this.getEstablecimientos(item.value);
        },
        sumarCampos(estab){
            let excepciones = ["rrhh","brecha","personal"];
            let obj = this.modelo[`est${estab.idestablecimiento}`];
            let total = 0;
            for(const prop in obj){
                let excep = excepciones.indexOf(prop);
                if(excep == -1){
                    total = total + Number(obj[prop]);
                }
            }
            this.modelo[`est${estab.idestablecimiento}`].rrhh = total;
            this.calcularBrecha(estab);
        },

        calcularBrecha(estab){
            this.modelo[`est${estab.idestablecimiento}`].brecha = this.modelo[`est${estab.idestablecimiento}`].personal - this.modelo[`est${estab.idestablecimiento}`].rrhh;
        },
    },
    created(){
        this.getRedes();
        this.getCargos();
    }
}
</script>

<style>

</style>