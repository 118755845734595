<template>
    <v-card>
        <v-progress-linear color="primary" indeterminate :height="9" v-if="cargando"></v-progress-linear>
        <v-card-text>
            <v-row class="pa-5 align-center">
                <v-col cols="11">
                    <v-row>
                        <h2 class="font-weight-regular text-center">Editar Grupo</h2>
                    </v-row>
                </v-col>
            </v-row>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="nombre"
                  :rules="fieldRules"
                  label="Nombre"
                  :disabled="cargando"
                  variant="outlined"
                ></v-text-field>
            
                <v-btn
                  class="me-4"
                  color="info"
                  :loading="loading"
                  :disabled="loading"
                  depressed
                  @mousedown="validate"
                  @click="guardar"
                >
                    Guardar 
                </v-btn>
                <v-btn
                  class="me-4 text-white"
                  color="danger"
                  to="/grupo"
                >
                    Regresar 
                </v-btn>
    
            </v-form>
        </v-card-text>
    </v-card>
</template>
<script>
import Swal from 'sweetalert2'

export default {
    data () {
        return {
            token : this.$store.getters.token,
            loading: false,
            cargando : true,
            codigo : this.$route.params.id,
            nombre : "",
            valid: true,
            fieldRules: [(v) => !!v || "Campo requerido"],
            
        }
    },
    methods : {
        validate() {
            this.$refs.form.validate();
        },

        crearEntidad(){
            return {
                "nombre": this.nombre.toUpperCase()
            }
        },

        guardar(){
            if (this.valid == false) return;
            this.loading = true;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method : 'PUT',
                url : '/api/grupo/'+this.codigo,
                data: this.crearEntidad()
            }).then(response => {
                this.loading = false;
                let icono = response.data.cod == 201 ? 'success' : 'error';
 
                Swal.fire({
                    title : "Sistema",
                    text: response.data.msje,
                    icon: icono
                });

                if(response.data.cod == 201){
                    this.$router.push('/grupo');
                }

            }).catch(e => {
                Swal.fire({
                    title : "Sistema",
                    text : e.message,
                    icon: "error"
                });
                this.$router.push('/login');
            })
        },
        getData(){
            this.cargando = true;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method : "GET",
                url : "/api/grupo/"+this.codigo
            }).then(response => {
                this.nombre = response.data.nombre;
                this.cargando = false;
            }).catch(e => {
                Swal.fire({
                    title : "Sistema",
                    text : e.message,
                    icon: "error"
                });
                this.$router.push('/login');
            })
        }
    },
    created(){
        this.getData();
    }
}
</script>
<style lang="">
    
</style>