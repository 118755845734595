import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '@/components/LoginView.vue'
import HomeView from '@/views/HomeView.vue'
import CategoriaView from "@/components/categoria/ListaView.vue";
import NuevaCategoriaView from "@/components/categoria/NewView.vue";
import EditaCategoriaView from "@/components/categoria/EditView.vue";
import RedView from "@/components/red/ListaView.vue";
import NuevaRedView from "@/components/red/NewView.vue";
import EditaRedView from "@/components/red/EditView.vue";
import SedeView from "@/components/microsede/ListaView.vue";
import NuevaSedeView from "@/components/microsede/NewView.vue";
import EditaSedeView from "@/components/microsede/EditView.vue";
import GrupoView from "@/components/grupo/ListaView.vue";
import NuevoGrupoView from "@/components/grupo/NewView.vue";
import EditaGrupoView from "@/components/grupo/EditView.vue";
import CargoView from "@/components/cargo/ListaView.vue";
import NuevoCargoView from "@/components/cargo/NewView.vue";
import EditaCargoView from "@/components/cargo/EditView.vue";
import EstablecimientoView from "@/components/establecimiento/ListaView.vue";
import NuevoEstablecimientoView from "@/components/establecimiento/NewView.vue";
import EditaEstablecimientoView from "@/components/establecimiento/EditView.vue";
import NuevoRegistroView from '@/components/consulta/NewView.vue'

import PersonalView from "@/components/personal/ListView.vue";
import NuevoPersonalView from "@/components/personal/NewView.vue";
import EditaPersonalView from "@/components/personal/EditView.vue";

import UsuarioView from "@/components/usuario/ListView.vue";
import NuevoUsuarioView from "@/components/usuario/NewView.vue";
import EditaUsuarioView from "@/components/usuario/EditView.vue";

import ReporteView from  "@/components/brecha/ReporteView.vue";

const routes = [

  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta:{
      auth: false,
    },
  },
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta:{
      auth: true,
    },
  },
  {
    path: '/categoria',
    name: 'Categoria',
    component: CategoriaView,
    meta:{
      auth: true,
    },
  },
  {
    path: '/nuevacategoria',
    name : 'Nueva Categoria',
    component: NuevaCategoriaView,
    meta:{
      auth: true,
    },
  },
  {
    path: '/editacategoria/:id',
    name : 'Edita Categoria',
    component: EditaCategoriaView,
    meta:{
      auth: true,
    },
  },
  {
    path: '/red',
    name: 'Red',
    component: RedView,
    meta:{
      auth: true,
    },
  },
  {
    path: '/nuevared',
    name : 'Nueva Red',
    component: NuevaRedView,
    meta:{
      auth: true,
    }, 
  },
  {
    path: '/editared/:id',
    name : 'Editar Red',
    component: EditaRedView,
    meta:{
      auth: true,
    }, 
  },
  {
    path: '/microsede',
    name: 'microsede',
    component: SedeView,
    meta:{
      auth: true,
    },
  },
  {
    path: '/nuevamicrosede',
    name : 'Nueva MicroSede',
    component: NuevaSedeView,
    meta:{
      auth: true,
    }, 
  },
  {
    path: '/editamicrosede/:id',
    name : 'Editar MicroSede',
    component: EditaSedeView,
    meta:{
      auth: true,
    }, 
  },
  {
    path: '/grupo',
    name: 'grupo',
    component: GrupoView,
    meta:{
      auth: true,
    },
  },
  {
    path: '/nuevogrupo',
    name : 'Nuevo Grupo',
    component: NuevoGrupoView,
    meta:{
      auth: true,
    }, 
  },
  {
    path: '/editagrupo/:id',
    name : 'Edita Grupo',
    component: EditaGrupoView,
    meta:{
      auth: true,
    }, 
  },
  {
    path: '/cargo',
    name: 'cargo',
    component: CargoView,
    meta:{
      auth: true,
    },
  },
  {
    path: '/nuevocargo',
    name : 'Nuevo cargo',
    component: NuevoCargoView,
    meta:{
      auth: true,
    }, 
  },
  {
    path: '/editacargo/:id',
    name : 'Edita cargo',
    component: EditaCargoView,
    meta:{
      auth: true,
    },
  },
  {
    path: '/establecimiento',
    name: 'establecimiento',
    component: EstablecimientoView,
    meta:{
      auth: true,
    },
  },
  {
    path: '/nuevoestablecimiento',
    name : 'Nuevo Establecimiento',
    component: NuevoEstablecimientoView,
    meta:{
      auth: true,
    }, 
  },
  {
    path: '/editaestablecimiento/:id',
    name : 'Edita Establecimiento',
    component: EditaEstablecimientoView,
    meta:{
      auth: true,
    },
  },
  {
    path: '/consultaexterna',
    name : 'Nuevo Registro',
    component: NuevoRegistroView,
    meta:{
      auth: true,
    }, 
  },
  {
    path: '/nuevopersonal',
    name : 'Nuevo Personal',
    component: NuevoPersonalView,
    meta:{
      auth: true,
    }, 
  },
  {
    path: '/editapersonal/:id',
    name : 'Edita Personal',
    component: EditaPersonalView,
    meta:{
      auth: true,
    }, 
  },
  {
    path: '/personal',
    name : 'Lista Personal',
    component: PersonalView,
    meta:{
      auth: true,
    }, 
  },
  {
    path: '/nuevousuario',
    name : 'Nuevo Usuario',
    component: NuevoUsuarioView,
    meta:{
      auth: true,
    }, 
  },
  {
    path: '/editausuario/:id',
    name : 'Edita Usuario',
    component: EditaUsuarioView,
    meta:{
      auth: true,
    }, 
  },
  {
    path: '/usuario',
    name : 'Lista Usuario',
    component: UsuarioView,
    meta:{
      auth: true,
    }, 
  },
  {
    path: '/reporte',
    name : 'Reporte de Brechas',
    component: ReporteView,
    meta:{
      auth: true,
    }, 
  }

]

const router = createRouter({
  
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    const token = localStorage.getItem('token');

    if (!token) {
      next({ name: 'Login' })
    } else {
      next();
    }
  } else {
    next()
  }
});

export default router
