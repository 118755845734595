<template>
    <v-card>
        <v-card-text>
            <v-row class="pa-5 align-center">
                <v-col cols="11">
                    <v-row>
                        <h2 class="font-weight-regular text-center">Nuevo Establecimiento</h2>
                    </v-row>
                </v-col>
            </v-row>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="nombre"
                  :rules="fieldRules"
                  label="Nombre"
                  variant="outlined"
                ></v-text-field>

                <v-combobox
                    v-model="categoria"
                    :items="categorias"
                    :rules="fieldRules"
                    density="comfortable"
                    label="Categoria"
                    variant="outlined"
                ></v-combobox>

                <v-combobox
                    v-model="horario"
                    :items="horarios"
                    :rules="fieldRules"
                    density="comfortable"
                    label="Horario"
                    variant="outlined"
                    multiple
                    chips
                ></v-combobox>

                <v-combobox
                    v-model="sede"
                    :items="sedes"
                    :rules="fieldRules"
                    density="comfortable"
                    variant="outlined"
                    label="Sede"
                ></v-combobox>
            
                <v-btn
                  class="me-4"
                  color="info"
                  :loading="loading"
                  :disabled="loading"
                  depressed
                  @mousedown="validate"
                  @click="guardar"
                >
                    Guardar 
                </v-btn>
                <v-btn
                  class="me-4 text-white"
                  color="danger"
                  to="/establecimiento"
                >
                    Regresar 
                </v-btn>
    
            </v-form>
        </v-card-text>
    </v-card>
</template>
<script>
import Swal from 'sweetalert2'
export default {
    data () {
        return {
            token : this.$store.getters.token,
            loading: false,
            nombre : "",
            sede : null,
            categoria : null,
            horario: null,
            valid: true,
            sedes : [],
            categorias: [],
            horarios : ["6 HORAS", "12 HORAS", "24 HORAS"],
            fieldRules: [(v) => !!v || "Campo requerido"],
            
        }
    },
    methods : {
        validate() {
            this.$refs.form.validate();
        },

        getHorario(){
            let cadena = "";
            let total = this.horario.length;
            let i = 1;
            this.horario.forEach(x => {
                if(i < total){
                    cadena = cadena + x + "/"
                }else{
                    cadena = cadena + x + ""
                }

                i = i + 1                
            });
            return cadena;
        },

        crearEntidad(){
            return {
                "nombre": this.nombre.toUpperCase(),
                "sede": this.sede.value,
                "categoria": this.categoria.value,
                "horario": this.getHorario()

            }
        },

        getSedes(){
            let array = [];
            let obj = null;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method: "get",
                url : "/api/sede/active"
            }).then(response =>{
                array = response.data;
                array.forEach(x => {
                    obj = {
                        "value" : x.idsede,
                        "title" : x.nombre
                    }
                    this.sedes.push(obj)
                });

            }).catch(e => {
                Swal.fire({
                    title : "Sistema",
                    text : e.message,
                    icon: "error"
                });
                this.$router.push('/login');
            });
        },
        getCategorias(){
            let array = [];
            let obj = null;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method: "get",
                url : "/api/categoria/active"
            }).then(response =>{
                array = response.data;
                array.forEach(x => {
                    obj = {
                        "value" : x.idcategoria,
                        "title" : x.nombre
                    }
                    this.categorias.push(obj)
                });

            }).catch(e => {
                Swal.fire({
                    title : "Sistema",
                    text : e.message,
                    icon: "error"
                });
                this.$router.push('/login');
            });
        },

        guardar(){
            if (this.valid == false) return;
            this.loading = true;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method : 'POST',
                url : '/api/establecimiento',
                data: this.crearEntidad()
            }).then(response => {
                this.loading = false;
                let icono = response.data.cod == 201 ? 'success' : 'error';
 
                Swal.fire({
                    title : "Sistema",
                    text: response.data.msje,
                    icon: icono
                });

                if(response.data.cod == 201){
                    this.$router.push('/establecimiento');
                }

            }).catch(e => {
                Swal.fire({
                    title : "Sistema",
                    text : e.message,
                    icon: "error"
                });
                this.$router.push('/login');
            })
        }
    },
    created() {
        this.getSedes();
        this.getCategorias();
    }
}
</script>
<style lang="">
    
</style>