import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { loadFonts } from './plugins/webfontloader'


loadFonts()

axios.defaults.baseURL = "https://apimk.redchiroque.com/"  //produccion
//axios.defaults.baseURL = "https://redchiroque.com/apimk/public/"  //produccion
axios.defaults.withCredentials = true;

import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap'
import 'datatables.net-bs5'
import 'datatables.net-buttons-bs5'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'

import JSZip from 'jszip';
window.JSZip = JSZip;

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;



createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueAxios,axios)
  .mount('#app')
  


