<template>
    <v-card>
        <v-row class="pa-5 align-center">
            <v-col cols="12">
                <h2 class="font-weight-bold text-center">Establecimientos</h2>
            </v-col>
        </v-row>
        <v-progress-linear color="primary" indeterminate :height="9" v-if="cargando"></v-progress-linear>
        <v-card-title>
            <v-row>
            <v-col cols="4">
                <v-btn 
                    icon="mdi-plus"
                    color="primary"
                    title="Nueva Establecimiento"
                    to="/nuevoestablecimiento"
                    :disabled="cargando"
                ></v-btn>
            </v-col>
            <v-col cols="8">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar..." single-line
                hide-details></v-text-field>
            </v-col>
            </v-row>        
        </v-card-title>
        <v-data-table dense items-per-page="5" :loading="cargando" :search="search" :headers="headers" :items="establecimientos"
            item-value="item" items-per-page-text="Total de Registros" class="elevation-1">
            <template v-slot:[`item.actions`]="{ item }">
            <v-icon title="Editar" color="success" small class="mr-2" @click="editItem(item)">
                mdi-pencil
            </v-icon>
            <v-icon title="Eliminar" color="error" small @click="deleteItem(item)">
                mdi-delete
            </v-icon>
            </template>
        </v-data-table>

    </v-card>

</template>
<script>
import Swal from 'sweetalert2'
import { VDataTable } from 'vuetify/labs/VDataTable'
 

export default {
    name : "lista",
    components: {
        VDataTable
    },
    data() {
        return {
            token : this.$store.getters.token,
            establecimientos : [],
            cargando : true,
            headers: [
                {
                    title: "Nombre",
                    align: "start",
                    key: "nombre"
                },
                {
                    title: "Categoria",
                    align: "start",
                    key: "categoria"
                },
                {
                    title: "Horario",
                    align: "start",
                    key: "horario"
                },
                {
                    title: "Sede",
                    align: "start",
                    key: "sede"
                },
                {
                    title: "Estado",
                    align: "start",
                    key: "estado"
                },
                {
                    title: "Opciones",
                    align: "start",
                    key: "actions",
                    sortable: false
                }

            ],
            search: null,
        }
    },
    methods : {
        getEstablecimientos(){
            this.cargando = true;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method: "get",
                url : "/api/establecimiento"
            }).then(response =>{
                this.establecimientos = response.data;
                this.cargando = false;
            }).catch(e => {
                Swal.fire({
                    title : "Sistema",
                    text : e.message,
                    icon: "error"
                });
                this.$router.push('/login');
            });
        },
        editItem(item){
            let row = item.raw;
            this.$router.push("/editaestablecimiento/"+row.idestablecimiento)
        },

        deleteItem(item){
            let row = item.raw;
            this.cargando = true;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method : "DELETE",
                url : "/api/establecimiento/"+row.idestablecimiento
            }).then(response => {
                let icono = response.data.cod == 201 ? 'success' : 'error'; 
                Swal.fire({
                    title : "Sistema",
                    text: response.data.msje,
                    icon: icono
                });

                if(response.data.cod == 201){
                    this.getEstablecimientos();
                }

                this.cargando = false;
            }).catch(e => {
                Swal.fire({
                    title : "Sistema",
                    text : e.message,
                    icon: "error"
                });
                this.$router.push('/login');
            })
        }
    },
    created(){
        this.getEstablecimientos();
    },
    

}
</script>

<style>
    @import 'bootstrap';
    @import 'datatables.net-dt';
</style>