<template>
    <v-layout>
        <v-app-bar
            color="primary"
            density="compact"
        >

            <v-app-bar-title>BRECHAS</v-app-bar-title>
        </v-app-bar>
        <v-main>
            <v-container fluid>
                <v-card
                    class="mx-auto"
                    width="400"
                    prepend-icon="mdi-home"
                >
                    <template v-slot:title>
                        Iniciar Sesión
                    </template>
                    <v-card-text>
                        <v-sheet width="300" class="mx-auto">
                        <v-form @submit.prevent="ingresar"  ref="form" v-model="valid" lazy-validation>
                            <v-text-field
                            v-model="email"
                                prepend-icon="mdi-account"
                                label="Usuario"
                                placeholder="Correo Electronico"
                                type="text"
                                :rules ="fieldRules"
                            />
                            <v-text-field
                                v-model="clave"
                                prepend-icon="mdi-lock"
                                label="Contraseña"
                                placeholder="Contraseña"
                                type="password"
                                :rules ="fieldRules"
                            />
                            <v-btn @mousedown="validate" :disabled="ingresando" type="submit" block color="primary">
                            <span v-if="!ingresando">Ingresar</span>
                            <v-progress-circular v-else size="20" width="2" indeterminate color="#FFF"></v-progress-circular>
                            </v-btn>
                        </v-form>
                        </v-sheet>
                    </v-card-text>
                </v-card>
                <v-footer class="d-flex flex-column">
          <v-col class="text-center mt-4" cols="12">
            {{ new Date().getFullYear() }} — <strong>RED CHIROQUE</strong>
          </v-col>
        </v-footer>
            </v-container>
        </v-main>
        
    </v-layout>    
  </template>
  
  <script>
  // @ is an alias to /src
  import Swal from "sweetalert2";
  import store from '@/store/index';

  export default {
    name: 'Login',
    components: {
    },
  
    data(){
         return {
              dialog: false,
              email:"",
              clave:"",
              ingresando: false,
              edit: false,
              valid: true,
              saveLoading: false,
              fieldRules: [(v) => !!v || "Campo requerido"],
              nombres : "",
              apellidos : "",
              correo: "",
              telefono: "",
              direccion: "",
              editId:  null
          }
    },
    methods: {
        validate() {
            this.$refs.form.validate();
        },
        ingresar(){
            if (this.valid == false) return;
            this.ingresando = true;          

            let obj = {
                "usuario": this.email,
                "clave": this.clave
            };


            this.axios.get('/sanctum/csrf-cookie').then(response => {
                // Login...
                this.axios({
                    method : 'POST',
                    url : "/api/login",
                    data: obj
                }).then(response => {
                    this.ingresando = false; 
                    if (response.data.status == 0) {                        
                        Swal.fire({
                            title : "Error",
                            text : response.data.msg,
                            icon : "error"
                        });
                    }else{
                        localStorage.setItem('token',JSON.stringify(response.data.token));
                        localStorage.setItem('user',JSON.stringify(response.data.user));
                        store.commit('updateUser');
                        this.$router.push('/');
                    }
                });
            }).catch(e => {
                Swal.fire({
                    title : "Error",
                    text : e.message,
                    icon : "error"
                })
            })

            

            //this.$router.push("/home");
  
          }
  
      }
  }
  </script>
  