<template>
    <v-card>
        <v-card-text>
            <v-row class="pa-5 align-center">
                <v-col cols="11">
                    <v-row>
                        <h2 class="font-weight-regular text-center">Nueva MicroSede</h2>
                    </v-row>
                </v-col>
            </v-row>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="nombre"
                  :rules="fieldRules"
                  label="Nombre"
                  variant="outlined"
                ></v-text-field>
                <v-combobox
                    v-model="red"
                    :items="redes"
                    :rules="fieldRules"
                    density="comfortable"
                    label="Red"
                    variant="outlined"
                ></v-combobox>
            
                <v-btn
                  class="me-4"
                  color="info"
                  :loading="loading"
                  :disabled="loading"
                  depressed
                  @mousedown="validate"
                  @click="guardar"
                >
                    Guardar 
                </v-btn>
                <v-btn
                  class="me-4 text-white"
                  color="danger"
                  to="/microsede"
                >
                    Regresar 
                </v-btn>
    
            </v-form>
        </v-card-text>
    </v-card>
</template>
<script>
import Swal from 'sweetalert2'
export default {
    data () {
        return {
            token : this.$store.getters.token,
            loading: false,
            nombre : "",
            red : null,
            valid: true,
            redes : [],
            fieldRules: [(v) => !!v || "Campo requerido"],
            
        }
    },
    methods : {
        validate() {
            this.$refs.form.validate();
        },

        crearEntidad(){
            return {
                "nombre": this.nombre.toUpperCase(),
                "red": this.red.value
            }
        },

        getRedes(){
            let array = [];
            let obj = null;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method: "get",
                url : "/api/red/active"
            }).then(response =>{
                array = response.data;
                array.forEach(x => {
                    obj = {
                        "value" : x.idred,
                        "title" : x.nombre
                    }
                    this.redes.push(obj)
                });

            }).catch(e => {
                Swal.fire({
                    title : "Sistema",
                    text : e.message,
                    icon: "error"
                })
                this.$router.push('/login');
            });
        },
        guardar(){
            if (this.valid == false) return;
            this.loading = true;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method : 'POST',
                url : '/api/sede',
                data: this.crearEntidad()
            }).then(response => {
                this.loading = false;
                let icono = response.data.cod == 201 ? 'success' : 'error';
 
                Swal.fire({
                    title : "Sistema",
                    text: response.data.msje,
                    icon: icono
                });

                if(response.data.cod == 201){
                    this.$router.push('/microsede');
                }

            }).catch(e => {
                Swal.fire({
                    title : "Sistema",
                    text: e.message,
                    icon: "error"
                })
                this.$router.push('/login');
            })
        }
    },
    created() {
        this.getRedes();
    }
}
</script>
<style lang="">
    
</style>