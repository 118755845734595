<template>
    <v-card>
        
        <v-card-text>
            <v-row class="pa-5 align-center">
                <v-col cols="11">
                    <v-row>
                        <h2 class="font-weight-regular text-center">Nuevo Usuario</h2>
                    </v-row>
                </v-col>
            </v-row>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            v-model="username"
                            :rules="[fieldRules.require]"
                            label="Username"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="email"
                            :rules="[fieldRules.emailvalid]"
                            label="Email"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="clave"
                            :rules="[fieldRules.require]"
                            label="Clave"
                            type="password"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="repclave"
                            :rules="fieldRules.passConfirm"
                            label="Repetir Clave"
                            type="password"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-combobox
                            v-model="personal"
                            :items="personales"
                            :rules="[fieldRules.require]"
                            density="comfortable"
                            label="Personal"
                            variant="outlined"
                        ></v-combobox>
                    </v-col>
                    
                </v-row>
            
                <v-btn
                  class="me-4"
                  color="info"
                  :loading="loading"
                  :disabled="loading"
                  depressed
                  @mousedown="validate"
                  @click="guardar"
                >
                    Guardar 
                </v-btn>
                <v-btn
                  class="me-4 text-white"
                  color="danger"
                  to="/usuario"
                >
                    Regresar 
                </v-btn>
    
            </v-form>
        </v-card-text>
    </v-card>
</template>
<script>
import Swal from 'sweetalert2'
export default {
    data () {
        return {
            token : this.$store.getters.token,
            loading: false,
            username : "",
            email : "",
            clave: "",
            repclave : "",
            personal : null,
            personales : [],
            valid: true,
            fieldRules: {
                require : (v) => !!v || "Campo requerido",
                emailvalid:  (v) => /.+@.+/.test(v) || 'Email no valido',
                passConfirm: [
                    v => !!v || "Confirm password",
                    v => v === this.clave || "Passwords do not match"
                ]
            },
            
        }
    },
    methods : {
        validate() {
            this.$refs.form.validate();
        },

        crearEntidad(){
            return {
                "username": this.username,
                "clave" : this.clave,
                "email" : this.email,
                "personal": this.personal.value
            }
        },

        guardar(){
            if (this.valid == false) return;
            this.loading = true;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method : 'POST',
                url : '/api/usuario',
                data: this.crearEntidad()
            }).then(response => {
                this.loading = false;
                let icono = response.data.cod == 201 ? 'success' : 'error';
 
                Swal.fire({
                    title : "Sistema",
                    text: response.data.msje,
                    icon: icono
                });

                if(response.data.cod == 201){
                    this.$router.push('/usuario');
                }

            }).catch(e => {
                Swal.fire({
                    title : "Sistema",
                    text: e.message,
                    icon: "error"
                });

                this.$router.push('/login');

            })
        },
        getPersonales(){
            let array = [];
            let obj = null;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method: "get",
                url : "/api/personal/active"
            }).then(response =>{
                array = response.data;
                array.forEach(x => {
                    obj = {
                        "value" : x.idpersonal,
                        "title" : x.nombres + " " + x.appaterno + " " + x.apmaterno
                    }
                    this.personales.push(obj)
                });

            }).catch(e => {
                Swal.fire({
                    title : "Sistema",
                    text : e.message,
                    icon: "error"
                })

                this.$router.push('/login');
            });
        },
    },
    created(){
        this.getPersonales();
    }
}
</script>
<style lang="">
    
</style>