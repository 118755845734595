<template>
    <v-card>
      <v-card-text>
          <v-row class="pa-5 align-center">
              <v-col cols="11">
                  <v-row>
                      <h2 class="font-weight-regular text-center">Reporte de Brechas Por Sedes</h2>
                  </v-row>
              </v-col>
          </v-row>
          <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                  <v-col cols="12">
                      <v-combobox
                          v-model="red"
                          :items="redes"
                          :rules="[fieldRules.required]"
                          density="comfortable"
                          variant="outlined"
                          label="RED"
                          @update:model-value="cambiaRed"
                      ></v-combobox>                    
                  </v-col>
                  <v-col cols="12">
                      <v-combobox
                          v-model="sede"
                          :items="sedes"
                          :rules="[fieldRules.required]"
                          density="comfortable"
                          variant="outlined"
                          label="Sede"
                          @update:model-value="cambiaSede"
                      ></v-combobox>
                  </v-col>
                  <v-col cols="12">
                      <v-combobox
                          v-model="cargo"
                          :items="cargos"
                          :rules="[fieldRules.required]"
                          density="comfortable"
                          variant="outlined"
                          label="Cargo"
                      ></v-combobox>
                  </v-col>
              </v-row>
              <v-row dense>
                  <v-col cols="12">
                      <v-btn
                          class="me-4"
                          color="success"
                          :loading="loading"
                          :disabled="loading"
                          depressed
                          @click = "exportInd"
                      >
                        <v-icon>mdi-file-excel</v-icon>
                          Exportar Individual 
                      </v-btn>
                      <v-btn
                          class="me-4"
                          color="warning"
                          :loading="loading"
                          :disabled="loading"
                          depressed
                          @click = "exportCons"
                      >
                        <v-icon>mdi-microsoft-excel</v-icon>
                          Exportar Consolidado 
                      </v-btn>
                  </v-col>
              </v-row>
              
          </v-form>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import Swal from 'sweetalert2'
  
  export default {
      data(){
          return {
              token : this.$store.getters.token,
              foo: 0,
              tab: null,
              cargo : null,
              red : null,
              sede : null,
              sedes : [],
              redes : [],
              loading: false,
              fijado : false,
              fijar : false,
              inputs : [],
              inputsfoot : [],
              cargos: [], 
              establecimientos : [],
              valid: true,            
              modelo : null,
              fieldRules: {
                  required: (v) => !!v || "Campo requerido",
                  number: (value) => {
                      const pattern = /^[0-9]/;
                      return pattern.test(value) || 'Solo numeros'
                  }
                  
              },
          }
      },
      methods : {  

          crearEntidad(){
              return {
                  "red": this.red.value,
                  "sede": this.sede.value,
                  "cargo": this.cargo.value,
              }
          }, 
          getCargos(){
              this.axios({
                  headers : {
                      Authorization : 'Bearer ' + this.token
                  },
                  method : "GET",
                  url: "/api/cargo/active"
              }).then(response => {
                  response.data.forEach(x =>{
                     this.cargos.push({
                          "title": x.nombre,
                          "value": x.idcargo
                      })
                  });
              }).catch(e =>{
                  Swal.fire({
                    title : "Sistema",
                    text : e.message,
                    icon: "error"
                });
                this.$router.push('/login');
              })
          },
          getRedes(){
              this.axios({
                  headers : {
                      Authorization : 'Bearer ' + this.token
                  },
                  method : "GET",
                  url: "/api/red/active"
              }).then(response => {
                  response.data.forEach(x =>{
                     this.redes.push({
                          "title": x.nombre,
                          "value": x.idred
                      })
                  });
              }).catch(e =>{
                Swal.fire({
                    title : "Sistema",
                    text : e.message,
                    icon: "error"
                });
                this.$router.push('/login');
              })
          },
          getSedes(item){
              this.axios({
                  headers : {
                      Authorization : 'Bearer ' + this.token
                  },
                  method : "GET",
                  url: "/api/sede/listForRed/"+item
              }).then(response => {
                  response.data.forEach(x =>{
                     this.sedes.push({
                          "title": x.nombre,
                          "value": x.idsede
                      })
                  });
              }).catch(e =>{
                Swal.fire({
                    title : "Sistema",
                    text : e.message,
                    icon: "error"
                });
                this.$router.push('/login');
              })
          },

          cambiaRed(item){
              this.getSedes(item.value);
          },
          cambiaSede(item){
              this.getCargos();
          },

          exportInd(){
            this.loading = true;

            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token,
                },
                method : "GET",
                url : "/api/exportar/excel",
            }).then(response => {
                this.loading = false;

                Swal.fire({
                    title : "Sistema",
                    text: response.data.msje,
                    icon: "success"
                });

                window.open(this.axios.defaults.baseURL + "/" + response.data.ruta , "_blank");
            }).catch(e => {
                this.loading = false;
                Swal.fire({
                    title : "Sistema",
                    text : e.message,
                    icon: "error"
                });
                this.$router.push('/login');
            })
          },
          exportCons(){
            this.loading = true;

            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token,
                },
                method : "GET",
                url : "/api/exportar/excelcons",
            }).then(response => {
                this.loading = false;

                Swal.fire({
                    title : "Sistema",
                    text: response.data.msje,
                    icon: "success"
                });

                window.open(this.axios.defaults.baseURL + "/" + response.data.ruta , "_blank");

            }).catch(e => {
                this.loading = false;
                Swal.fire({
                    title : "Sistema",
                    text : e.message,
                    icon: "error"
                });
                this.$router.push('/login');
            })
          }
      },
      created(){
          this.getRedes();
      }
  }
  </script>
  
  <style>
  
  </style>