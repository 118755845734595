<template>  
  <v-container class="pa-4 text-center">
    <v-progress-linear color="primary" indeterminate :height="9" v-if="cargando"></v-progress-linear>
    <v-card>
      <v-card-title>
        <h1 class="text-h3 p-2">
          {{ sede }}
        </h1>
      </v-card-title>
    </v-card>
    <v-row
      class="fill-height"
      align="center"
      justify="center"
    >
      <template v-for="(item, i) in items" :key="i">
        <v-col
          cols="12"
          md="4"
        >
          <v-hover >
            <v-card>
              <v-card-title :class="['text-h6', `bg-${item.color}`]">
                <v-icon>{{ item.icon }}</v-icon>
                {{ item.title }}
              </v-card-title>
              <v-card-text class="bg-white text--primary">
                <p
                  :class="['text-h2', 'p-2' ,`text-${item.color}` ,'fw-bold']"
                >
                  {{ item.total }}
                </p>
                <p
                :class="['text-h6',`text-${item.color}`]"
                >
                  Registros
                </p>
                <v-btn
                  :to="item.url"
                  :color="item.color"
                  :disabled="cargando"
                >
                  <v-icon>mdi-arrow-right</v-icon>
                  Ir
                </v-btn>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      token : this.$store.getters.token,
      cargando: true,
      sede: "RED LAMBAYEQUE",
      items: [
        {
          color: 'red-darken-4',
          icon: 'mdi-laptop',
          title: 'Redes',
          url: '/red',
          total: 0
        },
        {
          color: 'purple-darken-2',
          icon: 'mdi-office-building-outline',
          title: 'MicroSedes',
          url: '/microsede',
          total: 0
        },
        {
          color: 'green-darken-1',
          icon: 'mdi-account-group',
          title: 'Grupos',
          url: '/grupo',
          total: 0
        },
        {
          color: 'indigo-darken-2',
          icon: 'mdi-buffer',
          title: 'Cargos',
          url: '/cargo',
          total: 0
        },
        {
          color: 'blue-darken-2',
          icon: 'mdi-home-account',
          title: 'Establecimientos',
          url: '/establecimiento',
          total: 0
        },
        {
          color: 'orange-darken-2',
          icon: 'mdi-format-list-bulleted-type',
          title: 'Categorias',
          url: '/categoria',
          total: 0
        },
      ],
      transparent: 'rgba(255, 255, 255, 0)',
    };
  },

  created(){
    this.cargando = true;

    this.axios({
      method : "GET",
      url : "/api/proceso/total",
      headers : {
        Authorization : 'Bearer ' + this.token
      }
    }).then( response => {
      let data = response.data;
      data.forEach(element => {
        this.items.forEach(x => {
          if(x.url == element.url){
            x.total = element.total;
          }
        });
      });
      this.cargando = false;
    }).catch( e => {
      if (e.response.data.message == "Unauthenticated.") {
        this.$router.push("/login");
      }
    })
  }

};
</script>
