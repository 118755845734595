<template>
    <v-card>
      <v-layout >
        <v-app-bar
          color="primary"
          v-if="$route.name != 'Login'"         
        >
          <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

          <v-toolbar-title>BRECHAS</v-toolbar-title>

          <v-spacer></v-spacer>

        </v-app-bar>
        <v-navigation-drawer
            :mini-variant.sync="mini"
            v-model="drawer"
            permanent
            app
            v-if="$route.name != 'Login'"
            >
              <v-list>
                <v-list-item                  
                  :title="nameuser"
                  :subtitle="username"
                  prepend-avatar="https://redchiroque.com/img/user.png"
                ></v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-list density="compact">
                <v-list-subheader>OPCIONES</v-list-subheader>

                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  :value="item"
                  :to="item.url"
                  active-color="primary"
                >
                  <template v-slot:prepend>
                    <v-icon :icon="item.icon"></v-icon>
                  </template>

                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item>
              </v-list>
              <v-list >
                <v-list-item  @click="closeSession">
                  <template v-slot:prepend>
                    <v-icon color="primary">mdi-exit-to-app</v-icon>
                  </template>
                    <v-list-item-title color="primary">Salir</v-list-item-title>
                  </v-list-item> 
               </v-list>
        </v-navigation-drawer>  
        <v-main>
          <router-view></router-view>
        </v-main>
      </v-layout>
    </v-card>

</template>

<script>
import Login from "./components/LoginView.vue"
import store from "@/store/index"

export default {
  components : {
    Login
  },
  data() {
    return {
      company: "BRECHAS",
      mini: true,
      drawer : true,
      token : this.$store.getters.token,
      items: [
        {title: "Dashboard", rol:[1,2,3], icon: "mdi-desktop-mac-dashboard", url:"/"},
        {title: "Personal",rol: [1],icon: "mdi-account", url: "/personal"},
        {title: "Usuario",rol: [1],icon: "mdi-account-circle-outline", url: "/usuario"},
        {title: "Red",rol: [1],icon: "mdi-laptop", url: "/red"},
        {title: "MicroSede",rol: [2],icon: "mdi-office-building-outline", url: "/microsede"},
        {title: "Categoria",rol: [2],icon: "mdi-format-list-bulleted-type", url: "/categoria"},
        {title: "Grupo", rol: [1], icon: "mdi-account-group", url: "/grupo" },
        {title: "Cargo", rol: [1], icon: "mdi-buffer", url: "/cargo"},
        {title: "Establecimiento",rol: [1],icon: "mdi-home-account", url: "/establecimiento"},
        {title: "Registro",rol: [3],icon: "mdi-pencil-box", url: "/consultaexterna"},
        {title: "Brecha",rol: [1],icon: "mdi-chart-bar", url: "/reporte"},        
      ]
    };
  },

  methods: {
    closeSession() {
        this.axios({
          headers : {
              Authorization : 'Bearer ' + this.token
          },
          method: "POST",
          url: "/api/logout"
        }).then(response => {          
          store.commit('removeUser');
          this.$router.push('/login');
        }).catch(e => {
          store.commit('removeUser');
          this.$router.push('/login');
        })
        
      },
    
  },

  computed: {
    username() {
      return this.$store.getters.user == null ? null : this.$store.getters.user.email;
    },
    nameuser(){
      return this.$store.getters.user == null ? null : this.$store.getters.user.username;
    },

    rol(){
      return 1;
    }
  },
  created(){

  },

};
</script>
